<template>
	
	<!-- Main Sidebar -->
	<a-layout-sider
		collapsible
		class="sider-primary"
		breakpoint="lg"
		collapsed-width="0"
		width="250px"
		:collapsed="sidebarCollapsed"
		@collapse="$emit('toggleSidebar', ! sidebarCollapsed)"
		:trigger="null"
		:class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
		theme="light"
		:style="{ backgroundColor: 'transparent',}">
			<div class="brand"><img src="images/logo-ct-black.png" alt=""> <span>Muse Dashboard</span></div>
			<hr>

			<!-- Sidebar Navigation Menu -->
			<a-menu theme="light" mode="inline" >
				<a-menu-item>
					<router-link to="/principal">
						<span class="icon"><font-awesome-icon :icon="['fas', 'home']"/> </span>
						<span class="label">Dashboard</span>
					</router-link>
				</a-menu-item>
				<a-menu-item>
					<router-link to="/usuarios">
						<span class="icon"><font-awesome-icon :icon="['fas', 'user']"/></span>
						<span class="label">Usuarios</span>
					</router-link>
				</a-menu-item>
				<a-sub-menu>
					<template #title>
						<span class="icon"><font-awesome-icon :icon="['fas', 'envelope']"/></span>
						<span class="label">Bandeja Entrada</span>
					</template>
					<a-menu-item-group>
						<a-menu-item>
							<router-link to="/bandeja_entrada/listado">
								<span class="label">Listado</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/bandeja_entrada/aceptar_rechazar">
								<span class="label">Aceptar / Rechazar</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/bandeja_entrada/envio_sunat">
								<span class="label">Envio a SUNAT</span>
							</router-link>
						</a-menu-item>
					</a-menu-item-group>
				</a-sub-menu>
			</a-menu>
			<!-- / Sidebar Navigation Menu -->

			<!-- Sidebar Footer -->
			<div class="aside-footer">
			</div>
			<!-- / Sidebar Footer -->

	</a-layout-sider>
	<!-- / Main Sidebar -->

</template>

<script>

	export default ({
		props: {
			// Sidebar collapsed status.
			sidebarCollapsed: {
				type: Boolean,
				default: false,
			},
			
			// Main sidebar color.
			sidebarColor: {
				type: String,
				default: "primary",
			},
			
			// Main sidebar theme : light, white, dark.
			sidebarTheme: {
				type: String,
				default: "light",
			},
		},
		data() {
			return {
				sidebarCollapsedModel: this.sidebarCollapsed,
			}
		},
	})

</script>
